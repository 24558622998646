import { NavyButton, SelectDropdown } from '@orascom/common-components';
import styles from './deal-scheduled-meetings.module.scss';
import { useTranslation } from 'react-i18next';
import PlusIcon from '../../assets/icons/plus--white.svg';
export function ScheduledMeetings() {
  const { t } = useTranslation();

  return (
    <div className={styles['meetings']}>
      <p className={styles['title']}>{t('scheduledMeetings')}</p>
      <p className={styles['description']}>{t('addMeetingDescription')}</p>
      <div className={styles['meeting-date']}>
        <p>{t('meetingDate')}</p>
        <SelectDropdown
          placeholder={t('selectDate')}
          className={styles['select']}
          onChange={() => console.log('change')}
        />
        <div className={styles['add-btn']}>
          <NavyButton>
            <img src={PlusIcon} alt="" role="presentation" />
            {t('addMeeting')}
          </NavyButton>
        </div>
      </div>
    </div>
  );
}

export default ScheduledMeetings;
