import {
  PaginationPayload,
  Payload,
  UnitInterface,
  UnitDetails as UnitDetailsInterface,
  UnitPaymentTerms as UnitPaymentTermsInterface,
  UnitCompareInterface,
} from '@orascom/api-interfaces';
import { Network } from './network';
import { ENDPOINTS } from './endpoints';
import { toast } from 'react-toastify';

export class Unit {
  public static getSalesManUnits(
    searchParams?: URLSearchParams | string
  ): Promise<Payload<PaginationPayload<UnitInterface[]>[]>> {
    const baseUrl = process.env['NX__SALESMAN_API_ENDPOINT'];
    const unitsEndpoint = ENDPOINTS.getSalesManUnits.url();

    const url = new URL(`${baseUrl}${unitsEndpoint}`);

    if (searchParams) {
      url.search = searchParams.toString();
    }

    return Network.fetch(url.href, {
      method: ENDPOINTS.getSalesManUnits.method,
    });
  }

  public static addSalesManSavedUnit(unitId: number): Promise<Payload<void>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.addSalesManSavedUnit.url(unitId)}`,
      {
        method: ENDPOINTS.addSalesManSavedUnit.method,
      }
    );
  }

  public static deleteSalesManSavedUnit(
    unitId: number
  ): Promise<Payload<void>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.deleteSalesManSavedUnit.url(unitId)}`,
      {
        method: ENDPOINTS.deleteSalesManSavedUnit.method,
      }
    );
  }

  public static getSalesManUnitDetails(
    unitId: number
  ): Promise<Payload<UnitDetailsInterface>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.getSalesManUnitDetails.url(unitId)}`,
      {
        method: ENDPOINTS.getSalesManUnitDetails.method,
      }
    );
  }

  public static getSalesManUnitPaymentTerms(
    unitId: number
  ): Promise<Payload<Payload<UnitPaymentTermsInterface[]>>> {
    return Network.fetch(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.getSalesManUnitPaymentTerms.url(unitId)}`,
      {
        method: ENDPOINTS.getSalesManUnitPaymentTerms.method,
      }
    );
  }

  public static getSalesManCompareUnits(
    unitsIds: number[]
  ): Promise<Payload<UnitCompareInterface[]>> {
    const url = new URL(
      `${process.env['NX__SALESMAN_API_ENDPOINT']}${ENDPOINTS.compareUnits.path}`
    );
    for (const index in unitsIds) {
      url.searchParams.append(`unit_ids[${index}]`, `${unitsIds[index]}`);
    }

    return Network.fetch(url.toString(), {
      method: ENDPOINTS.compareUnits.method,
    });
  }

  public static downloadSalesManOmanSalesOffer(unitId: number): Promise<any> {
    const url = new URL(
      `${
        process.env['NX__SALESMAN_API_ENDPOINT']
      }${ENDPOINTS.downloadSalesManOmanSalesOffer.url(unitId)}`
    );

    return fetch(url.toString(), {
      method: ENDPOINTS.downloadSalesManOmanSalesOffer.method,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          process.env['NX_SALESMAN_ACCESS_TOKEN_KEY'] as string
        )}`,
      },
    }).then((res) => {
      if (!res.ok) {
        toast.error('This unit doesnt have a sales offer');
        throw new Error('Network response was not ok');
      }
      return res.blob();
    });
  }
}
