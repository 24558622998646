// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ScrollToTop } from '@orascom/common-components';
import { ROUTES } from '../api/routes';
import styles from './app.module.scss';
import { Route, Routes, useLocation } from 'react-router-dom';
import Sidebar from '../components/sidebar/sidebar';
import {
  CompareUnitsProvider,
  Header,
} from '@orascom/broker-sales-man-common-components';
import { CurrencyContextProvider } from '@orascom/utils';

export function App() {
  const location = useLocation();
  const showSidebar = !location.pathname.includes('login'); // TODO: change when users are added
  const availableRoutes = Object.values(ROUTES)
    .map((route) => {
      if (!route.public) {
        return;
      }

      const Component = route.component;

      return (
        <Route key={route.path} path={route.path} element={<Component />} />
      );
    })
    .filter((route) => route !== null);

  return (
    // TODO : condition on user in classname
    <CompareUnitsProvider>
      <CurrencyContextProvider defaultDollar>
        <main
          className={`${
            showSidebar ? 'container container--flex' : 'container'
          }`}
        >
          {showSidebar && <Sidebar />}
          <ScrollToTop />
          <section className={styles['side-section']}>
            {showSidebar && (
              <Header
                portal="Sales Man"
                notificationPath={ROUTES['Notifications'].path}
              />
            )}
            <Routes>{availableRoutes}</Routes>
          </section>
        </main>
      </CurrencyContextProvider>
    </CompareUnitsProvider>
  );
}

export default App;
