
      // Based on : https://javascript.plainenglish.io/how-to-override-methods-in-localstorage-36c7e5edc47

      ((win) => {
        const isLocalStorageAvailable = () => {
          try {
            const testKey = '__test__';
            localStorage.setItem(testKey, testKey);
            localStorage.removeItem(testKey);
            return true;
          } catch (e) {
            return false;
          }
        };

        const isSessionStorageAvailable = () => {
          try {
            const testKey = '__test__';
            sessionStorage.setItem(testKey, testKey);
            sessionStorage.removeItem(testKey);
            return true;
          } catch (error) {
            return false;
          }
        };

        if (typeof window !== 'undefined' && !isSessionStorageAvailable()) {
          const inMemorySessionStorage = {};
          class MySessionStorage {
            setItem(key, value) {
              inMemorySessionStorage[key] = value;
            }

            getItem(key) {
              return inMemorySessionStorage[key] || null;
            }
          }

          const mySessionStorage = new MySessionStorage();

          // Assign the newly created instance to localStorage
          Object.defineProperty(win, 'sessionStorage', {
            value: mySessionStorage,
            writable: true,
          });
        }

        if (typeof window !== 'undefined' && !isLocalStorageAvailable()) {
          const inMemoryStorage = {};

          class MyLocalStorage {
            setItem(key, value) {
              inMemoryStorage[key] = value;
            }

            getItem(key) {
              return inMemoryStorage[key] || null;
            }
          }

          const myLocalStorage = new MyLocalStorage();
          // Assign the newly created instance to localStorage
          Object.defineProperty(win, 'localStorage', {
            value: myLocalStorage,
            writable: true,
          });
        }
      })(window);
    