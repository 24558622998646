import { NavyButton, SelectDropdown } from '@orascom/common-components';
import styles from './lead-feedback.module.scss';
import { useTranslation } from 'react-i18next';

export function LeadFeedback() {
  const { t } = useTranslation();

  return (
    <div className={styles['lead-feedback']}>
      <p className={styles['title']}>{t('leadFeedback')}</p>
      <div className={styles['feedback-input']}>
        <div>
          <p>
            {t('feedback')} ({t('optional')})
          </p>
          <SelectDropdown
            placeholder={t('selectPlaceholder')}
            className={styles['select']}
            onChange={() => console.log('change')}
          />
        </div>
        <div>
          <p>{t('yourFeedback')}</p>
          <input
            className={styles['feedback-text']}
            type="text"
            placeholder={t('feedbackPlaceholder')}
          />
        </div>
        <div className={styles['feedback-btn']}>
          <NavyButton>{t('addFeeback')}</NavyButton>
        </div>
      </div>
    </div>
  );
}

export default LeadFeedback;
