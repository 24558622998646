import arrowIcon from '../../assets/icons/left-arrow.svg';
import { useTranslation } from 'react-i18next';
import styles from './disqualify-lead.module.scss';
import { ROUTES } from '../../api/routes';
import { Link } from 'react-router-dom';
import { useState } from 'react';
export function DisqualifyLead() {
  const { t } = useTranslation();

  const [selections, setSelections] = useState({
    clientNotInterested: false,
    clientNotResponsive: false,
    unitReserved: false,
    noStock: false,
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setSelections((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  return (
    <div className={styles['wrapper']}>
      <Link
        to={ROUTES['DealDetails'].getPath?.('456789')}
        className={styles['back-anchor']}
      >
        <img src={arrowIcon} alt="" role="presentation" />
        <span className="anchor">{t('back')}</span>
      </Link>
      <h1 className={styles['title']}>{t('disqualifyLead')}</h1>
      <p className={styles['subtitle']}>
        Why did you decide to cancel this deal?
      </p>
      <p className={styles['info']}>Select all that applies</p>
      <div className={styles['checkboxes']}>
        <button className={styles['checkbox-wrapper']}>
          <input
            name="clientNotInterested"
            type="checkbox"
            checked={selections.clientNotInterested}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="clientNotInterested">
            {t('clientNotInterested')}
          </label>
        </button>
        <button className={styles['checkbox-wrapper']}>
          <input
            name="clientNotResponsive"
            type="checkbox"
            checked={selections.clientNotResponsive}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="clientNotResponsive">
            {t('clientNotResponsive')}
          </label>
        </button>
        <button className={styles['checkbox-wrapper']}>
          <input
            name="unitReserved"
            type="checkbox"
            checked={selections.unitReserved}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="unitReserved">{t('unitReserved')}</label>
        </button>
        <button className={styles['checkbox-wrapper']}>
          <input
            name="noStock"
            type="checkbox"
            checked={selections.noStock}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="noStock">{t('noStock')}</label>
        </button>
      </div>
      <div className={styles['btns-wrapper']}>
        <button className={styles['disqualify-btn']}>
          <p>{t('disqualifyLead')}</p>
        </button>
        <button className={styles['cancel-btn']}>{t('cancel')}</button>
      </div>
    </div>
  );
}

export default DisqualifyLead;
