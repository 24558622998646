import {
  PaginationPayload,
  UnitInterface,
  FiltersEnums,
  Payload,
  UnitDetails as UnitDetailsInterface,
  UnitPaymentTerms,
  UnitCompareInterface,
} from '@orascom/api-interfaces';
import {
  downloadSalesOfferOman,
  getPayloadData,
  parseError,
} from '@orascom/utils';
import { Unit as UnitAPI } from '../api/unit';
export class Unit {
  public static getSalesManUnits(
    page: number,
    perPage: number,
    searchParams?: URLSearchParams
  ): Promise<PaginationPayload<UnitInterface[]>> {
    if (searchParams) {
      // on_resale is 0 by default (temporary)
      if (!searchParams.has(FiltersEnums.ON_RESALE)) {
        searchParams.append(FiltersEnums.ON_RESALE, '0');
      }

      searchParams.append('page', page.toString());
      searchParams.append('per_page', perPage.toString());
    }

    return UnitAPI.getSalesManUnits(searchParams)
      .then((result) => {
        return result.data[0];
      })
      .catch((error: string | object) => {
        return Promise.reject(parseError(error));
      });
  }

  public static deleteSalesManSavedUnit(
    unitId: number
  ): Promise<Payload<void>> {
    return UnitAPI.deleteSalesManSavedUnit(unitId)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error: Error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static addSalesManSavedUnit(unitId: number): Promise<Payload<void>> {
    return UnitAPI.addSalesManSavedUnit(unitId)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error: Error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getSalesManUnitDetails(
    unitId: number
  ): Promise<UnitDetailsInterface> {
    return UnitAPI.getSalesManUnitDetails(unitId)
      .then((result) => {
        return Promise.resolve(result?.data);
      })
      .catch((error: Error) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getSalesManUnitPaymentTerms(
    unitId: number
  ): Promise<UnitPaymentTerms[]> {
    return UnitAPI.getSalesManUnitPaymentTerms(unitId)
      .then((result) => {
        return Promise.resolve(result.data?.data ?? []);
      })
      .catch((error: unknown) => {
        return Promise.reject(parseError(error));
      });
  }

  public static getSalesManCompareUnits(
    unitsIds: number[]
  ): Promise<UnitCompareInterface[]> {
    return UnitAPI.getSalesManCompareUnits(unitsIds)
      .then((result) => {
        return Promise.resolve(getPayloadData(result));
      })
      .catch((error: unknown) => {
        return Promise.reject(parseError(error));
      });
  }

  public static downloadSalesManOmanSalesOffer(
    unitId: number,
    unitName: string
  ): Promise<any> {
    return UnitAPI.downloadSalesManOmanSalesOffer(unitId)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        downloadSalesOfferOman(url, unitName);
      })
      .catch((err: Error) => {
        console.log(err);
      });
  }
}
