import { Route, UserRole } from '@orascom/api-interfaces';
import Login from '../pages/login/login';
import Destination from '../pages/destination/destination';
import MyDeals from '../pages/my-deals/my-deals';
import NewDeal from '../pages/new-deal/new-deal';
import DealDetails from '../pages/deal-details/deal-details';
import Updates from '../pages/updates/updates';
import PrimaryUnits from '../pages/primary-units/primary-units';
import ResaleUnits from '../pages/resale-units/resale-units';
import { UnitDetails } from '../pages/unit-details/unit-details';
import ComparePropertiesPage from '../pages/compare-properties/compare-properties';
import DisqualifyLead from '../pages/disqualify-lead/disqualify-lead';

export const ROUTES: Record<string, Route> = {
  Login: {
    path: '/login',
    component: Login,
    public: true,
    role: UserRole.ALL,
  },
  Destination: {
    path: '/destination/:destinationSlug',
    getPath: (destinationSlug: string) => `/destination/${destinationSlug}`,
    component: Destination,
    public: true,
  },
  MyDeals: {
    path: '/my-deals',
    component: MyDeals,
    public: true,
  },
  NewDeal: {
    path: '/new-deal',
    component: NewDeal,
    public: true,
  },
  DealDetails: {
    path: '/deal-details/:dealId/:countryId',
    getPath: (dealId: string, countryId: string) =>
      `/deal-details/${dealId}/${countryId}`,
    component: DealDetails,
    public: true,
  },
  DisqualifyLead: {
    path: '/disqualify-lead/:dealId',
    getPath: (dealId: string) => `/disqualify-lead/${dealId}`,
    component: DisqualifyLead,
    public: true,
  },
  Notifications: {
    path: '/notifications',
    component: Updates,
    public: true,
  },
  PrimaryUnits: {
    path: '/primary-units',
    component: PrimaryUnits,
    public: true,
  },
  CompareProperties: {
    path: '/compare-properties',
    component: ComparePropertiesPage,
    public: true,
  },
  ResaleUnits: {
    path: '/resale-units',
    component: ResaleUnits,
    public: true,
  },
  UnitDetails: {
    path: '/unit-details/:unitId',
    getPath: (unitId: string) => `/unit-details/${unitId}`,
    component: UnitDetails,
    public: true,
  },
  UnitDeal: {
    path: '/unit-details/:unitId/new-deal',
    getPath: (unitId: string) => `/unit-details/${unitId}/new-deal`,
    component: NewDeal,
    public: true,
  },
};
