export const statusesOptions = [
  {
    label: 'lead discovery',
    value: 'lead discovery',
  },
  {
    label: 'sales pitch',
    value: 'sales pitch',
  },
  {
    label: 'deal closing',
    value: 'deal closing',
  },
];

export enum LeadStatus {
  LEAD_DISCOVERY = 'lead discovery',
  SALES_PITCH = 'sales pitch',
  DEAL_CLOSING = 'deal closing',
}

export enum DealStatusEnum {
  LEAD_DISCOVERY = 'lead_discovery',
  SALES_PITCH = 'sales_pitch',
  DEAL_CLOSING = 'deal_closing',
}
