import styles from './lead-activity-log.module.scss';
import { useTranslation } from 'react-i18next';

export function LeadActivityLog() {
  const { t } = useTranslation();

  return (
    <div className={styles['activity-log']}>
      <p className={styles['title']}>{t('activityLog')}</p>
      <table>
        <tr>
          <th>{t('action')}</th>
          <th>{t('date')}</th>
        </tr>
        <tbody>
          <tr>
            <td>
              <p>Lead Created</p>
            </td>
            <td>
              <p>19 August 2024, 03:37 PM</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Lead Created</p>
            </td>
            <td>
              <p>19 August 2024, 03:37 PM</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default LeadActivityLog;
