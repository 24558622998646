import { ReactNode } from 'react';
import './circular-icon.scss';

interface CircularIconProps {
  children: ReactNode;
}

export function CircularIcon(props: Readonly<CircularIconProps>) {
  return (
    <button className="circular-icon">
      <div className="circular-icon__circle">{props.children}</div>
    </button>
  );
}

export default CircularIcon;
