import { NavyButton, SelectDropdown } from '@orascom/common-components';
import styles from './deal-close.module.scss';
import { useTranslation } from 'react-i18next';

export function DealClose() {
  const { t } = useTranslation();

  return (
    <div className={styles['deal-close']}>
      <p className={styles['title']}>{t('closeDeal')}</p>
      <p className={styles['description']}>{t('closeDealDescription')}</p>
      <div className={styles['select-section']}>
        <p>{t('chooseUnit')}</p>
        <SelectDropdown
          placeholder={t('selectPlaceholder')}
          className={styles['select']}
          onChange={() => console.log('change')}
        />
        <p>{t('selectDate')}</p>
        <SelectDropdown
          placeholder={t('selectDate')}
          className={styles['select']}
          onChange={() => console.log('change')}
        />
        <div>
          <NavyButton>{t('scheduleClosing')}</NavyButton>
        </div>
      </div>
    </div>
  );
}

export default DealClose;
