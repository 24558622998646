import { useTranslation } from 'react-i18next';
import styles from './login.module.scss';
import logo from '../../assets/icons/logo-horizontal.png';
import { AuthWrapper } from '@orascom/broker-sales-man-common-components';
import { NavyAnchor } from '@orascom/common-components';
import MicrosoftIcon from '../../assets/icons/microsoft.svg';

export function Login() {
  const { t } = useTranslation();
  return (
    <AuthWrapper>
      <div className={styles['wrapper']}>
        <div className={styles['heading']}>
          <img
            src={logo}
            alt=""
            role="presentation"
            className={styles['logo']}
          />
          <h2 className={styles['subtitle']}>{t('internalSalesPortal')}</h2>
        </div>
        <div>
          <p>{t('signinUsingMicrosoft')}</p>
          <NavyAnchor
            className={styles['signin-btn']}
            href="https://api.orascom.robustastudio.com/login/microsoft"
          >
            <img src={MicrosoftIcon} alt="" role="presentation" />
            {t('signIn')}
          </NavyAnchor>
        </div>
      </div>
    </AuthWrapper>
  );
}

export default Login;
