import styles from './deal-details.module.scss';
import arrowIcon from '../../assets/icons/left-arrow.svg';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../api/routes';
import { Link } from 'react-router-dom';
import { DealStatusEnum } from '../../definitions/interfaces/deals.interface';
import DealDetailsBreadcrumbs from '../../components/deal-details-breadcrumbs/deal-details-breadcrumbs';
import DealInfoCard from '../../components/deal-info-card/deal-info-card';
import LeadFeedback from '../../components/lead-feedback/lead-feedback';
import LeadActivityLog from '../../components/lead-activity-log/lead-activity-log';
import ScheduledMeetings from '../../components/deal-scheduled-meetings/deal-scheduled-meetings';
import DealClose from '../../components/deal-close/deal-close';

export function DealDetails() {
  const { t } = useTranslation();
  return (
    <div className={styles['wrapper']}>
      <Link to={ROUTES['MyDeals'].path} className={styles['back-anchor']}>
        <img src={arrowIcon} alt="" role="presentation" />
        <span className="anchor">{t('back')}</span>
      </Link>
      <h1 className={styles['title']}>{t('leadDetails')}</h1>
      <div className="card">
        <DealDetailsBreadcrumbs dealStatus={DealStatusEnum.DEAL_CLOSING} />
        <div className={styles['deal-content']}>
          <div className={styles['deal-sections']}>
            <DealClose />
            <hr className={styles['line']} />
            <ScheduledMeetings />
            <hr className={styles['line']} />
            <LeadFeedback />
            <hr className={styles['line']} />
            <LeadActivityLog />
            <hr className={styles['line']} />
            <Link
              to={ROUTES['DisqualifyLead'].getPath?.('1')}
              className={styles['disqualify-link']}
            >
              <p> {t('disqualifyLead')}</p>
            </Link>
          </div>
          <DealInfoCard />
        </div>
      </div>
    </div>
  );
}

export default DealDetails;
